<template>
  <validation-observer
    ref="refinance-modal"
    v-slot="{ invalid }"
  >
    <b-modal
      id="model-pay-interest"
      :title="$t('pay interest')"
      :hide-header-close="true"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      footer-class="justify-content-end flex-row-reverse"
      :ok-title="$t('pay')"
      :ok-disabled="invalid"
      :cancel-title="$t('cancel')"
      cancel-variant="outline-secondary"
      @ok="paidSubmit('interest')"
      @hidden="defaultData"
    >
      <b-form>
        <b-row>
          <b-col cols="6">
            <b-form-group
              :label="$t('date')"
              label-for="add-date"
            >
              <b-form-datepicker
                id="add-date"
                v-model="paidInfo.date"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              :label="$t('time')"
              label-for="add-time"
            >
              <b-form-timepicker
                id="add-time"
                v-model="paidInfo.time"
                locale="en"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            v-if="customerData && customerData.loan.dueDateTime && customerData.loan.dueDateTime !== ''"
          >
            {{ $t('due date') + ': ' }}{{ customerData.loan.dueDateTime | moment('YYYY-MM-DD HH:mm') }}
          </b-col>
        </b-row>
        <b-row>
          <b-col
            v-if="customerData"
          >
            {{ $t('principal') + ': ' }}{{ numberWithCommas(customerData.loan.principal) }}
          </b-col>
        </b-row>
        <b-row class="pb-1">
          <b-col
            v-if="customerData"
          >
            {{ $t('payable') + ': ' }}{{ numberWithCommas(customerData.loan.payable) }}
          </b-col>
        </b-row>
        <b-row class="pb-1 text-danger">
          <b-col
            v-if="customerData && paidInfo.paid && paidInfo.paid > 0 && paidInfo.paid - customerData.loan.payable < 0"
          >
            {{ $t('penalty') + ': ' }}{{ numberWithCommas(paidInfo.paid - customerData.loan.payable) }}
          </b-col>
        </b-row>
        <b-row class="pb-1 text-success">
          <b-col
            v-if="customerData && paidInfo.paid && paidInfo.paid > 0 && paidInfo.paid - customerData.loan.payable > 0"
          >
            <div v-if="paidInfo.paid - customerData.loan.payable >= customerData.loan.principal">
              ({{ $t('closed') }}) {{ $t('allowance') + ': ' }}{{ numberWithCommas(paidInfo.paid - customerData.loan.payable - customerData.loan.principal) }}
            </div>
            <div v-else>
              {{ $t('allowance') + ': ' }}{{ numberWithCommas(paidInfo.paid - customerData.loan.payable) }}
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <validation-provider
              #default="{ errors }"
              :name="$t('amount')"
              rules="required|positive"
            >
              <b-form-group
                :label="$t('amount')"
                label-for="paid-amount"
              >
                <b-input-group
                  append=".00"
                >
                  <b-form-input
                    id="paid-amount"
                    v-model="paidInfo.paid"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group
              :label="$t('note')"
              label-for="textarea-note"
            >
              <b-form-textarea
                id="textarea-note"
                v-model="paidInfo.note"
                rows="3"
                :placeholder="$t('content')"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  ValidationProvider, ValidationObserver, localize, extend,
} from 'vee-validate'
import { required, positive } from '@validations'
import * as rules from 'vee-validate/dist/rules'
import store from '@/store'
import {
  BRow, BCol, BModal, VBModal, BFormGroup, BFormTextarea, BFormDatepicker, BFormInput, BFormTimepicker, BForm, BInputGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

localize('tw')
Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule])
})

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BInputGroup,
    BModal,
    BFormGroup,
    BFormTextarea,
    BFormInput,
    BFormDatepicker,
    BFormTimepicker,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    customerData: {
      type: Object,
      default: null,
      required: true,
    },
    userData: {
      type: Object,
      default: null,
      required: true,
    },
  },
  data() {
    return {
      positive,
      required,
      paidInfo: {
        date: this.$moment().format('YYYY-MM-DD'),
        time: this.$moment().format('HH:mm'),
        paid: '',
        note: '',
      },
    }
  },
  mounted() {
  },
  methods: {
    defaultData() {
      this.paidInfo = {
        date: this.$moment().format('YYYY-MM-DD'),
        time: this.$moment().format('HH:mm'),
        paid: '',
        note: '',
      }
    },
    async paidSubmit(type) {
      const check = false
      if (this.customerData && this.userData) {
        if (type === 'interest' && this.paidInfo.paid) {
          const dateTime = this.$moment(`${this.paidInfo.date} ${this.paidInfo.time}`, 'YYYY-MM-DD HH:mm').format()
          const data = {
            ...this.userData,
            customer: this.customerData,
            cid: this.customerData.cid,
            paid: this.paidInfo.paid,
            note: this.paidInfo.note,
            dateTime,
          }
          await store.dispatch('payInterest', data)
        } else if (check && type === 'penalty' && this.penaltyPaidInfo.paid) {
          const dateTime = this.$moment(`${this.penaltyPaidInfo.date} ${this.penaltyPaidInfo.time}`, 'YYYY-MM-DD HH:mm').format()
          const data = {
            ...this.user,
            customer: this.penaltyPaidInfo.customer,
            cid: this.penaltyPaidInfo.customer.cid,
            paid: this.penaltyPaidInfo.paid,
            note: this.penaltyPaidInfo.note,
            dateTime,
          }
          await store.dispatch('payPenalty', data)
        }
        this.defaultData()
      }
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  @import '@core/scss/vue/libs/vue-context.scss';
</style>
