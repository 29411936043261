<template>
  <div>
    <b-card-text class="mb-1">
      {{ $t('credit-related') }}
    </b-card-text>
    <b-row>
      <b-col cols="12">
        <b-form-group
          :label="$t('credit info')"
          label-for="credit"
        >
          <b-form-textarea
            id="credit"
            v-model="items.credit"
            :placeholder="$t('content')"
            :disabled="editDisabled"
            rows="10"
            @change="e => changeValue('credit', e)"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BCardText, BRow, BCol, BFormGroup, BFormTextarea,
} from 'bootstrap-vue'

export default {
  components: {
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormTextarea,
  },
  props: {
    items: {
      type: Object,
      default: null,
    },
    validations: {
      type: Array,
      default: null,
    },
    validateAction: {
      type: Boolean,
      default: false,
    },
    editDisabled: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
  },
  methods: {
    changeValue(key, value) {
      this.$emit('change', key, value)
    },
  },
}
</script>
