<template>
  <b-form-datepicker
    v-model="selfValue"
    :state="state"
    :disabled="disabled"
    @input="e => $emit('change', e)"
  />
</template>

<script>
import { BFormDatepicker } from 'bootstrap-vue'

export default {
  components: {
    BFormDatepicker,
  },
  props: {
    value: {
      type: String,
      default: undefined,
    },
    state: {
      type: Boolean,
      default: null,
    },
    disabled: Boolean,
  },
  data() {
    return {
      selfValue: '',
    }
  },
  mounted() {
    this.$set(this, 'selfValue', this.value)
  },
}
</script>
