<template>
  <div>
    <div
      v-for="(item, index) in data"
      :key="index"
      class="d-flex justify-content-between"
    >
      <div class="d-flex justify-content-start align-items-center">
        <div :class="`color-circle mr-50 bg-${payment[item.type]}`" />
        <b-card-text class="font-weight-bolder text-left mb-0">
          {{ item.loanDateTime | moment('YYYY/MM/DD') }}
        </b-card-text>
      </div>
      <b-card-text
        class="font-weight-bolder mb-0"
        :class="`text-${payment[item.type]}`"
      >
        ${{ numberWithCommas(item.principal) }}
      </b-card-text>
    </div>
    <div
      v-if="!data || data.length === 0"
      class="d-flex justify-content-between"
    >
      <b-card-text class="font-weight-bolder mb-0">
        {{ $t('no loan') }}
      </b-card-text>
    </div>
  </div>
</template>
<script>
import {
  BCardText,
} from 'bootstrap-vue'

export default {
  components: {
    BCardText,
  },
  props: {
    data: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      payment: {
        created: 'danger',
        closed: 'success',
        refinance: 'warning',
      },
    }
  },
  // data
}
</script>
<style>
.color-circle {
  width: 9px;
  height: 9px;
  border-radius: 50%;
  /* background: red; */
}
</style>
