<template>
  <div v-if="currentCustomer !== null && currentCustomer.cid === $route.query.id ">
    <b-row>
      <b-col lg="8">
        <b-card
          no-body
          class="pt-2 px-2"
          style="min-height: 149px;"
        >
          <b-row
            no-gutters
            @contextmenu.prevent="$refs.menu.open"
          >
            <!--
                Customer's profile
                * Avatar
                * Name
                * Account ID
                * Status
              -->
            <b-col md="5">
              <b-media
                no-body
                class="mb-2"
              >
                <b-media-aside
                  class="mr-1"
                >
                  <label for="fileInput">
                    <input
                      id="fileInput"
                      type="file"
                      hidden
                      accept="image/*"
                      @change="fileChange"
                    >
                    <b-avatar
                      :src="imgFile ? imgFile : currentCustomer.photoURL"
                      size="5.5rem"
                    />
                  </label>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <b-card-text class="font-small-4 font-weight-bolder mb-0">
                    {{ currentCustomer.basicInfo.fullName || '' }}
                  </b-card-text>
                  <b-card-text class="font-small-3 mb-50">
                    {{ currentCustomer.group }} {{ currentCustomer.basicInfo.accountNumber }}
                  </b-card-text>
                  <b-dropdown
                    v-if="currentCustomer.status"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    :text="$t(statusItems.find(d => d.key === currentCustomer.status).name)"
                    :variant="`flat-${statusItems.find(d => d.key === currentCustomer.status).type}`"
                    class="align-item-left"
                    size="sm"
                    style="width: 100px; align-items: left;"
                  >
                    <b-dropdown-item
                      v-for="item in statusItems"
                      :key="item.key"
                      :active="item.key === currentCustomer.status"
                      @click="changeStatus(item.key)"
                    >
                      {{ $t(item.name) }}
                    </b-dropdown-item>
                  </b-dropdown>
                </b-media-body>
              </b-media>
            </b-col>

            <!--
                Customer's payment summary
                * Total Received
                * Total Penalty
                * Total Notes
                * Loan Amount
                * Next Payment(15 days)
                * Next Payment Amount
              -->
            <b-col md="7">
              <b-row>
                <b-col
                  cols="3"
                  class="mb-2"
                >
                  <b-card-text class="font-small-4 font-weight-bolder line-height-2 mb-0">
                    {{ '$' + numberWithCommas(currentCustomer.totalReceived.paymentReceived) }}
                  </b-card-text>
                  <b-card-text class="font-small-2 line-height-1 text-muted mb-0">
                    {{ $t('total received') }}
                  </b-card-text>
                </b-col>
                <b-col
                  cols="3"
                  class="mb-2"
                >
                  <b-card-text class="font-small-4 font-weight-bolder line-height-2 mb-0">
                    {{ '$' + numberWithCommas(currentCustomer.totalReceived.penaltyReceived) }}
                  </b-card-text>
                  <b-card-text class="font-small-2 line-height-1 text-muted mb-0">
                    {{ $t('total received penalties') }}
                  </b-card-text>
                </b-col>
                <b-col
                  cols="3"
                  class="mb-2"
                >
                  <b-card-text class="font-small-4 font-weight-bolder line-height-2 mb-0">
                    {{ '$' + numberWithCommas(currentCustomer.totalReceived.allowanceReceived) }}
                  </b-card-text>
                  <b-card-text class="font-small-2 line-height-1 text-muted mb-0">
                    {{ $t('total received allowances') }}
                  </b-card-text>
                </b-col>
                <b-col
                  cols="3"
                  class="mb-2"
                >
                  <b-card-text class="font-small-4 font-weight-bolder line-height-2 mb-0">
                    ${{ numberWithCommas(currentCustomer.debtInfo.promissoryNotesAmount) + ' (' + $t(currentCustomer.debtInfo.promissoryNoteStatus) + ')' }}
                  </b-card-text>
                  <b-card-text class="font-small-2 line-height-1 text-muted mb-0">
                    {{ $t('promissory note') }}
                  </b-card-text>
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  cols="3"
                  class="mb-2"
                >
                  <b-card-text class="font-small-4 font-weight-bolder line-height-2 mb-0">
                    {{ '$' + numberWithCommas(currentCustomer.loan.principal) }}
                  </b-card-text>
                  <b-card-text class="font-small-2 line-height-1 text-muted mb-0">
                    {{ $t('principal') }}
                  </b-card-text>
                </b-col>
                <b-col
                  cols="3"
                  class="mb-2"
                >
                  <b-card-text
                    v-if="currentCustomer.loan.dueDateTime !== null && currentCustomer.loan.dueDateTime !== undefined && currentCustomer.loan.dueDateTime !== ''"
                    class="font-small-4 font-weight-bolder line-height-2 mb-0"
                  >
                    {{ currentCustomer.loan.dueDateTime | moment('YYYY/MM/DD') }}
                  </b-card-text>
                  <b-card-text
                    v-else
                    class="font-small-4 font-weight-bolder line-height-2 mb-0"
                  >
                    -
                  </b-card-text>
                  <b-card-text class="font-small-2 line-height-1 text-muted mb-0">
                    {{ `${$t('next payment')}` }}<span class="text-success">{{ `（+ ${currentCustomer.loan.paymentDuration}）` }}</span>
                  </b-card-text>
                </b-col>
                <b-col
                  cols="3"
                  class="mb-2"
                >
                  <b-card-text class="font-small-4 font-weight-bolder line-height-2 mb-0">
                    {{ '$' + numberWithCommas(currentCustomer.loan.payable) }}
                  </b-card-text>
                  <b-card-text class="font-small-2 line-height-1 text-muted mb-0">
                    {{ $t('payable') }}
                  </b-card-text>
                </b-col>
                <b-col
                  v-if="currentCustomer.loan.penalty && currentCustomer.loan.penalty > 0"
                  cols="3"
                  class="mb-2"
                >
                  <b-card-text class="font-small-4 font-weight-bolder line-height-2 mb-0">
                    {{ '$' + numberWithCommas(currentCustomer.loan.penalty) }}
                  </b-card-text>
                  <b-card-text class="font-small-2 line-height-1 text-muted mb-0">
                    {{ $t('penalty') }}
                  </b-card-text>
                </b-col>
                <b-col
                  v-else
                  cols="3"
                  class="mb-2"
                >
                  <b-card-text class="font-small-4 font-weight-bolder line-height-2 mb-0">
                    {{ numberWithCommas(currentCustomer.debtInfo.interestRate) + ' %' }}
                  </b-card-text>
                  <b-card-text class="font-small-2 line-height-1 text-muted mb-0">
                    {{ $t('interest rate') }}
                  </b-card-text>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
        <vue-context ref="menu">
          <li v-if="$can('update', 'customers')">
            <b-link
              class="d-flex align-items-center"
              @click="recalculate"
            >
              <feather-icon
                icon="RefreshCcwIcon"
                size="16"
              />
              <span class="ml-75">{{ $t('recalculate transactions') }}</span>
            </b-link>
          </li>
          <li v-if="$can('delete', 'customers')">
            <b-link
              v-b-modal.customer-delete-modal
              class="d-flex align-items-center"
            >
              <feather-icon
                icon="Trash2Icon"
                size="16"
              />
              <span class="ml-75">{{ $t('delete account') }}</span>
            </b-link>
          </li>
        </vue-context>
      </b-col>

      <!--
          Lending History
        -->
      <b-col lg="4">
        <b-card style="height: 149px;">
          <b-card-text class="font-weight-bolder mb-50">
            {{ $t('lending histories') }}
          </b-card-text>
          <div
            class="font-small-3 overflow-auto pr-1"
            style="height: 80px;"
          >
            <history-list
              :data="currentCustomer.loanHistories"
            />
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <b-card>
          <div class="d-flex">

            <!--
                Button group
                Return a key emitted from a button clicked
              -->
            <loan-button-group
              :buttons="groupButtons"
              :active-key="activeInfoBtnGroup"
              variant="primary"
              @activeKey="setActiveInfoBtnGroup"
            />

            <!--
                Dropdown menu - Actions for a customer
                * Edit // Edit a customer's note (Modal view)
                * Delete // Delete a current customer from db (Modal confirm)
                * Show only if active button is transaction
              -->
            <div
              v-show="activeInfoBtnGroup === 'transactions'"
              class="flex-grow-1 text-right"
            >
              <b-dropdown
                v-if="$can('create', 'customers')"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                toggle-class="px-0 py-50 bg-transparent"
                variant="flat-dark"
                no-caret
                right
              >
                <template
                  v-slot:button-content
                >
                  <feather-icon
                    id="customer-action"
                    icon="MoreVerticalIcon"
                    size="18"
                    class="cursor-pointer"
                  />
                </template>
                <b-dropdown-item
                  v-if="!currentCustomer.loan.dueDateTime || currentCustomer.loan.dueDateTime === ''"
                  v-b-modal.customer-add-loan-modal
                >
                  {{ $t('loan') }}
                </b-dropdown-item>
                <!-- <b-dropdown-item
                    v-if="currentCustomer.loan.penalty"
                  >
                    {{ $t('pay penalty') }}xxxx
                  </b-dropdown-item> -->
                <b-dropdown-item
                  v-if="currentCustomer.loan.dueDateTime"
                  v-b-modal.model-pay-interest
                >
                  {{ $t('pay interest') }}
                </b-dropdown-item>
                <b-dropdown-item
                  v-b-modal.customer-pay-fee-modal
                >
                  {{ $t('pay other fees') }}
                </b-dropdown-item>
                <b-dropdown-divider
                  v-if="currentCustomer.loan.dueDateTime"
                />
                <b-dropdown-item
                  v-if="currentCustomer.loan.dueDateTime"
                  v-b-modal.customer-refinance-modal
                >
                  {{ $t('refinance') }}
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="currentCustomer.loan.dueDateTime"
                  v-b-modal.customer-extend-date-modal
                >
                  {{ $t('extend due date') }}
                </b-dropdown-item>
                <!-- <b-dropdown-item
                    @click="recalculate"
                  >
                    {{ $t('recalculate transactions') }}
                  </b-dropdown-item> -->
                <!-- <b-dropdown-item
                    v-b-modal.customer-delete-modal
                  >
                    {{ $t('delete account') }}
                  </b-dropdown-item> -->
              </b-dropdown>
            </div>
          </div>

          <!--
              Component for a Section selected by group button
              Transaction, Basic Info, Family Info, Guarantor, Credit-related, Debt-related
              * is: A section component
              * items: The fields displayed in each section (type: object)
              * validations: Array of the validation(key, validate) for each field in a section selected
              * validateAction: Boolean value to indicate either a section will be validates or not
              * editDisabled: Boolean value to indicate either a section is editable or not
              * change: Function called whenever each field is changed in a section
            -->
          <component
            :is="groupButtons.find(d => d.key === activeInfoBtnGroup).component"
            :items="currentCustomer[activeInfoBtnGroup]"
            :validations="validations"
            :validate-action="validateAction"
            :edit-disabled="editDisabled"
            @change="changeValue"
            @changeArray="changeArray"
          />

          <!--
              Edit or Save, Cancel buttons of a section selected
              * Edit // Active editable status
              * Save // Save the changes of the current section
              * Cancel // Return to disable the active status of the current section
              Hide for a Transaction section
            -->
          <div v-show="activeInfoBtnGroup !== 'transactions'">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              :disabled="!$can('update', 'customers')"
              @click="editActionClick"
            >
              {{ !editDisabled ? $t('save') : $t('edit') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-dark"
              @click="cancelActionClick"
            >
              {{ $t('cancel') }}
            </b-button>
          </div>

        </b-card>
      </b-col>
    </b-row>

    <!--------------------- Customer add loan Modal --------------------->
    <validation-observer
      ref="add-loan-modal"
      v-slot="{ invalid }"
    >
      <b-modal
        id="customer-add-loan-modal"
        :title="$t('loan')"
        :hide-header-close="true"
        :no-close-on-backdrop="true"
        :no-close-on-esc="true"
        :ok-title="$t('save')"
        :cancel-title="$t('cancel')"
        cancel-variant="outline-primary"
        footer-class="justify-content-end flex-row-reverse"
        centered
        :ok-disabled="invalid"
        @cancel="setDefault('addLoan')"
        @ok="createLoan"
      >
        <b-row>
          <b-col>
            <b-form-group
              :label="$t('loan type')"
              label-for="loan-type"
            >
              <b-dropdown
                id="loan-type"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                class="mb-1 full-width-dropdown"
                variant="outline-primary"
                block
                :disabled="true"
                :text="$t(addLoan.loanType)"
              >
                <b-dropdown-item
                  v-for="item in LoanTypes"
                  :key="item.key"
                  @click="handleLoanType(item.key)"
                >
                  {{ $t(item.key) }}
                </b-dropdown-item>
              </b-dropdown>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group
              :label="$t('loan start date')"
              label-for="loan-start-date"
            >
              <flat-pickr
                id="loan-start-date"
                v-model="addLoan.dateTime"
                class="form-control"
                :config="{ enableTime: true,dateFormat: 'Y-m-d H:i'}"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <validation-provider
              #default="{ errors }"
              :name="$t('loan amount')"
              rules="min_value:1"
            >
              <b-form-group
                :label="$t('loan amount')"
                label-for="loan-amount"
              >
                <b-input-group
                  prepend="$"
                  append=".00"
                >
                  <b-form-input
                    id="loan-amount"
                    v-model="addLoan.amount"
                    :placeholder="$t('loan amount')"
                  />
                </b-input-group>
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group
              :label="$t('interest rate')"
              label-for="interest-rate"
            >
              <b-input-group
                append="%"
              >
                <b-form-input
                  id="loan-amount"
                  v-model="addLoan.interestRate"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group
              :label="$t('payment duration')"
              label-for="payment-duration"
            >
              <b-input-group
                :append="$t('days')"
              >
                <b-form-input
                  id="payment-duration"
                  v-model="addLoan.paymentDuration"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-checkbox
              v-model="addLoan.firstPayment"
              class="custom-control-primary"
            >
              {{ $t('interest deduction') }}
            </b-form-checkbox>
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col>
            <b-form-checkbox
              v-model="addLoan.deductToday"
              class="custom-control-primary"
            >
              {{ $t('deduct today from next due date') }}
            </b-form-checkbox>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col sm="6">
            {{ $t('loan amount') + ': ' + numberWithCommas(addLoan.amount) }}
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="6">
            {{ $t('interest rate') + ': ' + addLoan.interestRate + ' %' }}
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="6">
            {{ $t('payment duration') + ': ' + addLoan.paymentDuration }}
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="6">
            {{ $t('next payment') + ': ' }}{{ nextDueDate(addLoan.dateTime, addLoan.paymentDuration, addLoan.deductToday) }}
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="6">
            {{ $t('pay per period') + ': ' }}{{ numberWithCommas(countPayable(addLoan.amount, addLoan.interestRate)) }}
          </b-col>
        </b-row>
        <hr>
        <b-row>
          <b-col sm="6">
            {{ $t('fund appropriation') + ': ' }}{{ numberWithCommas((addLoan.firstPayment) ? addLoan.amount - countPayable(addLoan.amount, addLoan.interestRate) : addLoan.amount) }}
          </b-col>
        </b-row>
      </b-modal>
    </validation-observer>

    <validation-observer
      ref="refinance-modal"
      v-slot="{ invalid }"
    >
      <b-modal
        id="customer-refinance-modal"
        :title="$t('refinance')"
        :hide-header-close="true"
        :no-close-on-backdrop="true"
        :no-close-on-esc="true"
        :ok-title="$t('save')"
        :cancel-title="$t('cancel')"
        cancel-variant="outline-primary"
        footer-class="justify-content-end flex-row-reverse"
        :ok-disabled="invalid"
        centered
        @ok="startRefinance"
        @cancel="setDefault('refinance')"
      >
        <b-row>
          <b-col>
            <b-form-group
              :label="$t('execution date time')"
              label-for="loan-start-date"
            >
              <flat-pickr
                id="loan-start-date"
                v-model="refinance.dateTime"
                class="form-control"
                :config="{ enableTime: true,dateFormat: 'Y-m-d H:i'}"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              :label="$t('interest rate')"
              label-for="interest-rate"
            >
              <b-input-group
                append="%"
              >
                <b-form-input
                  id="loan-amount"
                  v-model="refinance.interestRate"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <validation-provider
              #default="{ errors }"
              :name="$t('days')"
              rules="required|positive"
            >
              <b-form-group
                :label="$t('payment duration')"
                label-for="payment-duration"
              >
                <b-input-group
                  :append="$t('days')"
                >
                  <b-form-input
                    id="refinanceDays"
                    v-model="refinance.paymentDuration"
                    :disabled="true"
                  />
                </b-input-group>
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <validation-provider
              #default="{ errors }"
              :name="$t('refinance amount')"
              rules="min_value:0"
            >
              <b-form-group
                :label="$t('refinance amount')"
                label-for="refinance-amount"
              >
                <b-input-group
                  prepend="$"
                  append=".00"
                >
                  <b-form-input
                    id="refinance-amount"
                    v-model="refinance.amount"
                    :placeholder="$t('loan amount')"
                  />
                </b-input-group>
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <validation-provider
              #default="{ errors }"
              :name="$t('days')"
              rules="required|positive"
            >
              <b-form-group
                :label="$t('extend days')"
                label-for="extend days"
              >
                <b-input-group
                  :append="$t('days')"
                >
                  <b-form-input
                    id="refinanceDays"
                    v-model="refinance.days"
                  />
                </b-input-group>
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            class="radio-inline-spacing"
          >
            <b-form-radio
              v-model="refinance.firstPayment"
              name="some-radios"
              value="total"
            >
              {{ $t('total interest deduction') }}
            </b-form-radio>
            <b-form-radio
              v-model="refinance.firstPayment"
              name="some-radios"
              value="current"
            >
              {{ $t('interest deduction') }}
            </b-form-radio>
            <b-form-radio
              v-model="refinance.firstPayment"
              name="some-radios"
              value="none"
            >
              {{ $t('none') }}
            </b-form-radio>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col sm="6">
            {{ $t('loan amount') + ': ' + numberWithCommas(refinance.amount) }}
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="6">
            {{ $t('interest rate') + ': ' + refinance.interestRate + ' %' }}
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="6">
            {{ $t('payable') + ': ' }}{{ numberWithCommas(countPayable(refinance.amount, refinance.interestRate)) }}
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="6">
            {{ $t('payment duration') + ': ' + refinance.paymentDuration }}
          </b-col>
        </b-row>
        <b-row>
          <b-col
            v-if="currentCustomer.loan.dueDateTime && currentCustomer.loan.dueDateTime !== ''"
          >
            {{ $t('due date') + ': ' }}{{ currentCustomer.loan.dueDateTime | moment('YYYY-MM-DD HH:mm') }}
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col>
            {{ $t('extend due date') + ': ' }}{{ extendDate(currentCustomer.loan.dueDateTime, refinance.days) }}
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="6">
            {{ $t('total loan') + ': ' }}{{ numberWithCommas(add(currentCustomer.loan.principal, refinance.amount)) }}
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="6">
            {{ $t('pay per period') + ': ' }}{{ numberWithCommas(countPayable(add(currentCustomer.loan.principal, refinance.amount), refinance.interestRate)) }}
          </b-col>
        </b-row>
        <hr>
        <b-row>
          <b-col sm="6">
            {{ $t('fund appropriation') + ': ' }}{{ numberWithCommas(appropriationCalculations('refinance')) }}
          </b-col>
        </b-row>
      </b-modal>
    </validation-observer>

    <validation-observer
      ref="pay-fee-modal"
      v-slot="{ invalid }"
    >
      <b-modal
        id="customer-pay-fee-modal"
        :title="$t('pay other fees')"
        :hide-header-close="true"
        :no-close-on-backdrop="true"
        :no-close-on-esc="true"
        :ok-title="$t('save')"
        :cancel-title="$t('cancel')"
        cancel-variant="outline-primary"
        footer-class="justify-content-end flex-row-reverse"
        :ok-disabled="invalid"
        centered
        @ok="addFee"
      >
        <b-row>
          <b-col>
            <b-form-group
              :label="$t('date time')"
              label-for="fee-date-time"
            >
              <flat-pickr
                id="fee-date-time"
                v-model="fee.dateTime"
                class="form-control"
                :config="{ enableTime: true,dateFormat: 'Y-m-d H:i'}"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="6">
            <validation-provider
              #default="{ errors }"
              :name="$t('allowance')"
              rules="required|positive|min_value:1"
            >
              <b-form-group
                :label="$t('allowance')"
                label-for="allowance"
              >
                <b-form-input
                  id="feeAllowance"
                  v-model="fee.allowance"
                  :placeholder="$t('allowance')"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col sm="6" />
        </b-row>
        <b-row>
          <b-col>
            <validation-provider
              #default="{ errors }"
              :name="$t('note')"
              rules="required"
            >
              <b-form-group
                :label="$t('note')"
                label-for="textarea-note"
              >
                <b-form-textarea
                  id="textarea-note"
                  v-model="fee.note"
                  rows="3"
                  :placeholder="$t('content')"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
        </b-row>
      </b-modal>
    </validation-observer>
    <b-modal
      id="customer-extend-date-modal"
      :title="$t('extend due date')"
      :hide-header-close="true"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      :ok-title="$t('save')"
      :cancel-title="$t('cancel')"
      cancel-variant="outline-primary"
      footer-class="justify-content-end flex-row-reverse"
      centered
      @ok="addDueDateDays"
    >
      <b-row>
        <b-col>
          <b-form-group
            :label="$t('execution date time')"
            label-for="loan-start-date"
          >
            <flat-pickr
              id="loan-start-date"
              v-model="extendDueDate.dateTime"
              class="form-control"
              :config="{ enableTime: true,dateFormat: 'Y-m-d H:i'}"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="6">
          <b-form-group
            :label="$t('allowance')"
            label-for="allowance"
          >
            <b-form-input
              id="extendAllowance"
              v-model="extendDueDate.allowance"
              :placeholder="$t('allowance')"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            :label="$t('extend days')"
            label-for="days"
          >
            <b-form-input
              id="allowance"
              v-model="extendDueDate.days"
              :placeholder="$t('days')"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            :label="$t('note')"
            label-for="textarea-note"
          >
            <b-form-textarea
              id="textarea-note"
              v-model="extendDueDate.note"
              rows="3"
              :placeholder="$t('content')"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          {{ $t('payment duration') + ': ' + currentCustomer.debtInfo.paymentDuration }}
        </b-col>
      </b-row>
      <b-row>
        <b-col
          v-if="currentCustomer.loan.dueDateTime && currentCustomer.loan.dueDateTime !== ''"
        >
          {{ $t('due date') + ': ' }}{{ currentCustomer.loan.dueDateTime | moment('YYYY-MM-DD HH:mm') }}
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          {{ $t('extend due date') + ': ' }}{{ extendDate(currentCustomer.loan.dueDateTime, extendDueDate.days) }}
        </b-col>
      </b-row>
    </b-modal>

    <!--------------------- Customer interest rate Modal --------------------->
    <b-modal
      id="customer-interest-rate-modal"
      :title="$t('Interest rate')"
      :hide-header-close="true"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      :ok-title="$t('save')"
      :cancel-title="$t('cancel')"
      cancel-variant="outline-primary"
      footer-class="justify-content-end flex-row-reverse"
      centered
    >
      <b-form-group
        :label="$t('interest rate')"
        label-for="interest-rate"
      >
        <b-dropdown
          id="interest-rate"
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          :text="rates.find(d => d === rate).toString() + '%'"
          variant="outline-primary"
          class="w-100 full-width-dropdown"
        >
          <b-dropdown-item
            v-for="item in rates"
            :key="item"
            @click="rate = item"
          >
            {{ item.toString() + '%' }}
          </b-dropdown-item>
        </b-dropdown>
      </b-form-group>
    </b-modal>

    <!--------------------- Customer delete Modal --------------------->
    <b-modal
      id="customer-delete-modal"
      :hide-header-close="true"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      :ok-title="$t('yes, delete it!')"
      :cancel-title="$t('cancel')"
      footer-class="justify-content-center flex-row-reverse"
      body-class="text-center"
      cancel-variant="outline-danger"
      size="md"
      hide-header
      centered
      @ok="deleteCustomer"
    >
      <div class="d-flex justify-content-center py-1">
        <feather-icon
          icon="AlertCircleIcon"
          size="80"
          style="color: #FF9F43;"
        />
      </div>
      <h4 class="px-2 mb-2">
        {{ $t('Are you sure to delete this customer?') }}
      </h4>
      <h6 class="text-muted">
        {{ $t('You wont be able to revert this!') }}
      </h6>
    </b-modal>
    <PayInterest
      :customer-data="getCurrentCustomer"
      :user-data="user"
    />
  </div>
</template>
<script>
import {
  ValidationProvider, ValidationObserver, localize, extend,
} from 'vee-validate'
import { required, positive } from '@validations'
import * as rules from 'vee-validate/dist/rules'
import store from '@/store'
import { mapGetters } from 'vuex'
import {
  BCard, BRow, BCol, BMedia, BMediaAside, BMediaBody, BAvatar, BCardText, BDropdown, BDropdownItem, BButton, BOverlay,
  BModal, VBModal, BFormGroup, BFormTextarea, BFormDatepicker, BFormInput, BDropdownDivider, BLink, BFormCheckbox, BInputGroup, BFormRadio,
  BInputGroupPrepend, BInputGroupAppend,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import {
  round, multiply, divide, add, subtract,
} from 'mathjs'
import VueContext from 'vue-context'
import { LoanTypes } from '@/store/cfg'

import HistoryList from './components/HistoryList.vue'
import LoanButtonGroup from './components/elements/LoanButtonGroup.vue'
import TransactionInfo from './components/TransactionInfo.vue'
import BasicInfo from './components/customer-steps/BasicInfo.vue'
import FamilyInfo from './components/customer-steps/FamilyInfo.vue'
import Guarantor from './components/customer-steps/Guarantor.vue'
import CreditRelated from './components/customer-steps/CreditRelated.vue'
import DebtRelated from './components/customer-steps/DebtRelated.vue'

import PayInterest from './components/model/PayInterest.vue'

const validationKeys = ['basicInfo', 'familyInfo', 'guarantorInfo', 'creditInfo', 'debtInfo']
localize('tw')
Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule])
})

export default {
  components: {
    VueContext,
    BLink,
    BCard,
    BRow,
    BCol,
    BMedia,
    BInputGroup,
    BFormRadio,
    BFormCheckbox,
    BInputGroupPrepend,
    BInputGroupAppend,
    BMediaAside,
    BMediaBody,
    BAvatar,
    BCardText,
    BDropdown,
    BDropdownItem,
    BButton,
    BOverlay,
    BModal,
    BFormGroup,
    BFormTextarea,
    BFormInput,
    BFormDatepicker,
    BDropdownDivider,
    HistoryList,
    LoanButtonGroup,
    BasicInfo,
    FamilyInfo,
    Guarantor,
    CreditRelated,
    DebtRelated,
    PayInterest,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      add,
      subtract,
      LoanTypes,
      positive,
      required,
      prevRoute: null,
      currentCustomer: null,
      renewTransaction: false,
      customerId: '', // Customer's id
      fee: {
        dateTime: this.$moment().format('YYYY-MM-DD HH:mm'),
        allowance: 0,
        note: null,
      },
      extendDueDate: {
        dateTime: this.$moment().format('YYYY-MM-DD HH:mm'),
        days: 0,
        allowance: 0,
        note: null,
      },
      refinance: {
        dateTime: this.$moment().format('YYYY-MM-DD HH:mm'),
        days: 0,
        amount: 0,
        note: null,
        firstPayment: 'total',
        paymentDuration: 0,
        interestRate: 0,
      },

      /** processing, observed, closed, bad_debt */
      statusItems: [
        {
          key: 'processing',
          name: 'Processing',
          type: 'primary',
        },
        {
          key: 'observed',
          name: 'observed',
          type: 'warning',
        },
        {
          key: 'closed',
          name: 'closed',
          type: 'success',
        },
        {
          key: 'bad_debt',
          name: 'bad debt',
          type: 'danger',
        },
        {
          key: 'active',
          name: 'active',
          type: 'info',
        },
        {
          key: 'inactive',
          name: 'inactive',
          type: 'secondary',
        },
        {
          key: 'dishonored',
          name: 'dishonored',
          type: 'danger',
        },
      ],
      /**
        Array of the buttons to category the customer information
        - transaction, basic, family, guarantor, credit, debt -
        This button group has the radio feature
      */
      groupButtons: [
        {
          key: 'transactions',
          name: 'Transactions',
          component: TransactionInfo,
        },
        {
          key: 'basicInfo',
          name: 'basic info',
          component: BasicInfo,
        },
        {
          key: 'familyInfo',
          name: 'family info',
          component: FamilyInfo,
        },
        {
          key: 'guarantorInfo',
          name: 'guarantor',
          component: Guarantor,
        },
        {
          key: 'creditInfo',
          name: 'credit-related',
          component: CreditRelated,
        },
        {
          key: 'debtInfo',
          name: 'debt-related',
          component: DebtRelated,
        },
      ],

      /** Array of all the VALIDATIONS for all the fields in a category in customer information */
      validations: [],

      currentCustomerNoRequiredFields: store.state.app.customerInfoNoRequiredFields,

      /** Key of a button activated(clicked) */
      activeInfoBtnGroup: 'transactions',
      actionSectionKey: '',

      /** Variable to indicate if all the field controls in a current category would show/hide the validation property when 'Save' button will be clicked */
      validateAction: false,

      /** Variable to indicate if all the field controls in a current category would be edit disabled/enabled the validation property when 'Edit' button will be clicked */
      editDisabled: true,

      imgFile: null, // Avatar image

      saveSpinner: false, // Overlay and Spinner durning access to db

      /**
       Add loan data
       * Loan start date
       * Loan amount
       * Payment duration
       * note
      */
      addLoan: {
        dateTime: this.$moment().format('YYYY-MM-DD 12:00'),
        amount: 0,
        firstPayment: true,
        deductToday: true,
        interestRate: 0,
        paymentDuration: 0,
        loanType: 'balloon',
      },
      rates: [10, 15, 20, 25, 30, 35, 40],
      rate: 15,
    }
  },
  computed: {
    ...mapGetters({
      getCurrentCustomer: 'getCurrentCustomer',
      user: 'getUser',
    }),
    validate() { // Get a validation for all the fields of the current category
      const arr = []
      this.validations.forEach(d => {
        if (Array.isArray(d)) {
          d.forEach(p => arr.push(p.validate))
        } else {
          arr.push(d.validate)
        }
      })
      return !(arr.indexOf(false) > -1)
    },
  },
  watch: {
    getCurrentCustomer(newVal) {
      this.customerSetUp(newVal)
    },
    activeInfoBtnGroup(newValue) { // When each button is clicked
      if (validationKeys.includes(newValue)) {
        let validations = []
        const stepInfo = this.currentCustomer[newValue]
        switch (Array.isArray(stepInfo)) {
          case false:
            Object.keys(stepInfo).forEach(itemKey => {
              if (this.currentCustomerNoRequiredFields[this.activeInfoBtnGroup].indexOf(itemKey) === -1) {
                validations.push({
                  key: itemKey,
                  validate: this.currentCustomer[this.activeInfoBtnGroup][itemKey] !== '',
                })
              }
            })
            break
          case true:
            validations = stepInfo.map((item, index) => Object.keys(item).map(itemKey => ({
              key: itemKey,
              validate: stepInfo[index][itemKey] !== '',
            })))
            break
          default:
        }
        this.$set(this, 'validations', validations)
        /* this.$set(this, 'validations', Object.keys(this.currentCustomer[newValue]).map(itemKey => ({
          key: itemKey,
          validate: this.currentCustomer[this.activeInfoBtnGroup][itemKey] !== '' && this.currentCustomerNoRequiredFields[this.activeInfoBtnGroup].indexOf(itemKey) === -1,
        }))) */
      }

      /**
        validateAction is false when a group button is clicked.
        This'll be updated when Save button is clicked.
      */
      this.$set(this, 'validateAction', false)

      /**
        editDisabled is false when a group button is clicked.
        This'll be updated as true when a "Edit" button is clicked,
      */
      this.$set(this, 'editDisabled', true)
    },
  },
  created() {
    this.$set(this, 'customerId', this.$route.query.id)
    if (this.$route.query.section && this.$route.query.section !== '') {
      this.$set(this, 'actionSectionKey', this.$route.query.section)
    }

    store.dispatch('fetchCustomer', { cid: this.$route.query.id }).then(data => {
      this.customerSetUp(data)
      this.setDefault('refinance')
      this.setDefault('addLoan')
    })

    store.dispatch('fetchCustomerTransactions', { cid: this.$route.query.id })

    /* const file = this.$store.state.app.avatarFile
    if (file) {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        this.$set(this, 'imgFile', reader.result)
      }
    } */
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      // eslint-disable-next-line no-param-reassign
      vm.prevRoute = from
    })
  },
  mounted() {
    const backName = (this.prevRoute && this.prevRoute.name && this.prevRoute.name !== 'customer-register') ? this.prevRoute.name : 'customers'
    store.commit('app/UPDATE_NAV_BACK_BUTTON', { buttonShow: true, backName })
  },
  beforeRouteLeave(to, from, next) {
    /** Set to hide in Store for a Back button  */
    store.commit('app/UPDATE_NAV_BACK_BUTTON', { buttonShow: false, backName: 'customers' })
    next()
  },
  methods: {
    setDefault(type) {
      if (type === 'refinance') {
        this.refinance = {
          dateTime: this.$moment().format('YYYY-MM-DD HH:mm'),
          days: 0,
          amount: 0,
          note: null,
          firstPayment: 'total',
          paymentDuration: this.getCurrentCustomer.debtInfo.paymentDuration,
          interestRate: this.getCurrentCustomer.debtInfo.interestRate,
        }
      } else if (type === 'addLoan') {
        this.addLoan = {
          dateTime: this.$moment().format('YYYY-MM-DD 12:00'),
          amount: 0,
          firstPayment: true,
          deductToday: true,
          paymentDuration: this.getCurrentCustomer.debtInfo.paymentDuration,
          interestRate: this.getCurrentCustomer.debtInfo.interestRate,
          loanType: 'balloon',
        }
      }
    },
    appropriationCalculations(type) {
      if (type === 'refinance' && this.refinance.amount > 0) {
        const interestRate = divide(this.refinance.interestRate, 100)
        if (this.refinance.firstPayment === 'none') {
          return this.refinance.amount
        } if (this.refinance.firstPayment === 'total') {
          return +subtract(this.refinance.amount, multiply(add(this.refinance.amount, this.getCurrentCustomer.loan.principal), interestRate))
        }
        return +subtract(this.refinance.amount, multiply(this.refinance.amount, interestRate))
      }
      return 0
    },
    handleLoanType(type) {
      this.addLoan.loanType = type
    },
    nextDueDate(date, paymentDuration, deductToday) {
      if (deductToday) {
        return this.$moment(date, 'YYYY-MM-DD HH:mm').add(paymentDuration - 1, 'd').format('YYYY-MM-DD HH:mm')
      }
      return this.$moment(date, 'YYYY-MM-DD HH:mm').add(paymentDuration, 'd').format('YYYY-MM-DD HH:mm')
    },
    extendDate(date, duration) {
      return this.$moment(date, 'YYYY-MM-DD HH:mm').add(duration, 'd').format('YYYY-MM-DD HH:mm')
    },
    countPayable(principal, interestRate) {
      const interest = divide(interestRate, 100)
      return round(multiply(principal, interest))
    },
    changeStatus(key) {
      this.$set(this, 'currentCustomer', { // Update the state(currentCustomer.profile.status) for status(observed, processing, closed, bad debt) of a customer
        ...this.currentCustomer,
        status: key,
      })
      this.$set(this, 'saveSpinner', true)
      store.dispatch('app/updateCustomerWithIdFromDb', { id: this.currentCustomer.cid, update: { status: key } }).then(() => {
        this.$set(this, 'saveSpinner', false)
        this.$bvToast.toast('A status was updated successfully.', {
          title: 'Success',
          variant: 'success',
          solid: true,
          toaster: 'b-toaster-top-center',
        })
      })
    },
    setActiveInfoBtnGroup(key) {
      this.$set(this, 'activeInfoBtnGroup', key)
    },

    changeValue(key, value, row) {
      let obj = null
      const stepInfo = this.currentCustomer[this.activeInfoBtnGroup]
      switch (Array.isArray(stepInfo)) {
        case false:
          this.$set(this, 'currentCustomer', { // Update the state for any field changed in the current category
            ...this.currentCustomer,
            [this.activeInfoBtnGroup]: {
              ...this.currentCustomer[this.activeInfoBtnGroup],
              [key]: value,
            },
          })

          if (!this.currentCustomerNoRequiredFields[this.activeInfoBtnGroup].includes(key)) {
            if (value !== '') { // A validation of a field changed is true as that is Not empty
              this.validations.find(d => d.key === key).validate = true
            } else { // A validation of a field changed is false as that is empty
              this.validations.find(d => d.key === key).validate = false
            }
          }

          break
        case true:
          obj = [...stepInfo]
          obj[row] = {
            ...obj[row],
            [key]: value,
          }
          this.$set(this, 'currentCustomer', { // Update the state for any field changed in the current step
            ...this.currentCustomer,
            [this.activeInfoBtnGroup]: [...obj],
          })
          if (value !== '') { // A validation of a field changed is true as that is Not empty
            this.validations[row].find(d => d.key === key.split('.')[0]).validate = true
          } else { // A validation of a field changed is false as that is empty
            this.validations[row].find(d => d.key === key.split('.')[0]).validate = false
          }
          break
        default:
      }
    },

    /**
     Change that array in the case stepInfo is array
     * index: item's index
     * action: add or remove
     */
    changeArray(index, action) {
      switch (action) {
        case 'add':
          this.$set(this, 'currentCustomer', {
            ...this.currentCustomer,
            [this.activeInfoBtnGroup]: [
              ...this.currentCustomer[this.activeInfoBtnGroup],
              this.$store.state.app.blankcurrentCustomer[this.activeInfoBtnGroup][0],
            ],
          })
          this.$set(this, 'validations', [
            ...this.validations,
            Object.keys(this.$store.state.app.blankcurrentCustomer[this.activeInfoBtnGroup][0]).map(d => ({
              key: d,
              validate: false,
            })),
          ])
          break
        case 'remove':
          this.currentCustomer[this.activeInfoBtnGroup].splice(index, 1)
          this.validations.splice(index, 1)
          this.$set(this, 'currentCustomer', {
            ...this.currentCustomer,
            [this.activeInfoBtnGroup]: [
              ...this.currentCustomer[this.activeInfoBtnGroup],
            ],
          })
          this.$set(this, 'validations', [...this.validations])
          break
        default:
      }
    },

    /**
     When "Edit/Save" button is clicked
     */
    editActionClick() {
      if (this.editDisabled) { // When "Edit" button is clicked
        this.$set(this, 'validateAction', false)
        this.$set(this, 'editDisabled', false)
      } else if (this.validate) { // If a validation for all the fields is true When "Save" button is clicked
        this.$set(this, 'saveSpinner', true)
        let update = {
          [this.activeInfoBtnGroup]: this.currentCustomer[this.activeInfoBtnGroup],
        }
        if (this.activeInfoBtnGroup === 'debtInfo') {
          update = {
            ...update,
            'loan.paymentDuration': this.currentCustomer[this.activeInfoBtnGroup].paymentDuration,
            'loan.interestRate': this.currentCustomer[this.activeInfoBtnGroup].interestRate,
          }
        }
        if (this.activeInfoBtnGroup === 'basicInfo') {
          update = {
            ...update,
            group: update.basicInfo.group,
          }
        }
        store.dispatch('app/updateCustomerWithIdFromDb', { id: this.currentCustomer.cid, update }).then(() => {
          this.$set(this, 'saveSpinner', false)
          this.$bvToast.toast('A customer was updated successfully.', {
            title: 'Success',
            variant: 'success',
            solid: true,
            toaster: 'b-toaster-top-center',
          })
        })
        this.$set(this, 'validateAction', false)
        this.$set(this, 'editDisabled', true)
      } else { // If a validation for all the fields is false When "Save" button is clicked
        this.$set(this, 'validateAction', true)
        this.$set(this, 'editDisabled', false)
      }
    },

    cancelActionClick() { // When "Cancel" button is clicked
      this.$set(this, 'validateAction', false)
      this.$set(this, 'editDisabled', true)
    },

    /**
     When avatar image is changed (avatar image)
     */
    fileChange(event) {
      const file = event.target.files[0]
      if (file) {
        this.$set(this, 'saveSpinner', true)
        store.dispatch('app/setAvatarFile', { file, save: true }).then(res => {
          this.$set(this, 'saveSpinner', false)
          if (res.status === 'success') {
            this.$bvToast.toast('Your profile image was updated successfully.', {
              title: 'Success',
              variant: 'success',
              solid: true,
              toaster: 'b-toaster-top-center',
            })
          } else {
            this.$bvToast.toast(res.error, {
              title: 'Failed',
              variant: 'danger',
              solid: true,
              toaster: 'b-toaster-top-center',
            })
          }
        })
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
          this.$set(this, 'imgFile', reader.result)
        }
      }
    },
    customerSetUp(newVal) {
      this.$set(this, 'currentCustomer', { ...newVal })
      this.currentCustomer.transactions = []

      /** Set State (validations) from currentCustomer for a validation of each field */
      if (validationKeys.includes(this.activeInfoBtnGroup)) {
        let validations = []
        const stepInfo = newVal[this.activeInfoBtnGroup]
        switch (Array.isArray(stepInfo)) {
          case false:
            Object.keys(stepInfo).forEach(itemKey => {
              if (this.currentCustomerNoRequiredFields[this.activeInfoBtnGroup].indexOf(itemKey) === -1) {
                validations.push({
                  key: itemKey,
                  validate: this.currentCustomer[this.activeInfoBtnGroup][itemKey] !== '',
                })
              }
            })
            break
          case true:
            validations = stepInfo.map((item, index) => Object.keys(item).map(itemKey => ({
              key: itemKey,
              validate: stepInfo[index][itemKey] !== '',
            })))
            break
          default:
        }
        this.$set(this, 'validations', validations)
        /* this.$set(this, 'validations', Object.keys(newVal[this.activeInfoBtnGroup]).map(itemKey => ({
          key: itemKey,
          validate: this.currentCustomer[this.activeInfoBtnGroup][itemKey] !== '' && this.currentCustomerNoRequiredFields[this.activeInfoBtnGroup].indexOf(itemKey) === -1,
        }))) */
      }

      if (this.actionSectionKey !== '') {
        this.$set(this, 'activeInfoBtnGroup', this.actionSectionKey)
        this.$set(this, 'actionSectionKey', '')
      }
    },
    /**
     When "Yes, delete it!" button is clicked
     */
    deleteCustomer() {
      this.$set(this, 'saveSpinner', true)
      store.dispatch('deleteCustomer', this.getCurrentCustomer).then(() => {
        this.$bvToast.toast('A customer was deleted successfully.', {
          title: 'Success',
          variant: 'success',
          solid: true,
          toaster: 'b-toaster-top-center',
        })
        this.$set(this, 'saveSpinner', false)
        this.$router.push({ name: 'customers' })
      }).catch(error => {
        this.$bvToast.toast(error, {
          title: 'Failed',
          variant: 'danger',
          solid: true,
          toaster: 'b-toaster-top-center',
        })
        this.$set(this, 'saveSpinner', false)
      })
    },
    createLoan() {
      store.dispatch('createLoan', {
        customer: this.currentCustomer,
        ...this.user,
        cid: this.currentCustomer.cid,
        ...this.addLoan,
      })
    },
    addDueDateDays() {
      store.dispatch('extendLoanDueDate', {
        customer: this.currentCustomer,
        ...this.user,
        cid: this.currentCustomer.cid,
        ...this.extendDueDate,
      })
    },
    addFee() {
      store.dispatch('createFee', {
        customer: this.currentCustomer,
        ...this.user,
        cid: this.currentCustomer.cid,
        ...this.fee,
      })
    },
    recalculate() {
      if (!this.renewTransaction) {
        this.renewTransaction = true
        store.dispatch('reCalculateCustomerTransactions', {
          customer: this.currentCustomer,
          cid: this.currentCustomer.cid,
        })
      }
    },
    startRefinance() {
      store.dispatch('refinance', {
        customer: this.currentCustomer,
        ...this.user,
        cid: this.currentCustomer.cid,
        ...this.refinance,
      })
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  @import '@core/scss/vue/libs/vue-context.scss';

  .radio-inline-spacing {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    & > * {
      margin-right: 1.5rem;
      margin-left: 1rem;
    }
  }
</style>
