<template>
  <div>
    <b-table
      id="all-table"
      responsive
      striped
      :items="getCustomerTransactions($route.query.id)"
      :fields="fields"
      primary-key="tid"
      :sort-by.sync="sortBy"
      :sort-desc.sync="isSortDirDesc"
      table-class="custom-table"
    >
      <template #cell(createdAt)="data">
        {{ data.item.createdAt | moment('YYYY/MM/DD hh:mm') }}
      </template>

      <template #cell(interestRate)="data">
        <div v-if="data.value">
          {{ data.value + '%' }}
        </div>
      </template>

      <template #cell(type)="data">
        {{ $t(data.value) }}
      </template>

      <template #cell(principal)="data">
        {{ numberWithCommas(data.value) }}
      </template>

      <template #cell(payable)="data">
        {{ numberWithCommas(data.value) }}
      </template>

      <template #cell(paymentReceived)="data">
        {{ numberWithCommas(data.value) }}
      </template>

      <template #cell(allowance)="data">
        {{ numberWithCommas(data.value) }}
      </template>

      <template #cell(penalty)="data">
        {{ numberWithCommas(data.value) }}
      </template>

      <!------------------- Action column ----------------->
      <template #cell(action)="data">
        <b-dropdown
          v-if="$can('delete', 'transactions')"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          toggle-class="px-0 py-50 bg-transparent"
          variant="flat-dark"
          no-caret
          right
        >
          <template
            v-slot:button-content
          >
            <feather-icon
              :id="`box-pop-menu-${data.item.id}`"
              icon="MoreVerticalIcon"
              size="18"
              class="cursor-pointer"
            />
          </template>
          <!-- <b-dropdown-item
            v-b-modal.transaction-loan-edit-modal
          >
            {{ $t('loan') }}
          </b-dropdown-item> -->
          <b-dropdown-item
            v-b-modal.transaction-delete-modal
            @click="selectedData = data.item"
          >
            {{ $t('delete') }}
          </b-dropdown-item>
        </b-dropdown>
      </template>

      <template #head()="data">
        <div style="min-width: 30px;">
          {{ $t(data.label) }}
        </div>
      </template>

    </b-table>

    <!--------------------- Transaction Loan Modal --------------------->
    <b-modal
      id="transaction-loan-edit-modal"
      title="Income"
      ok-title="Save"
      cancel-title="Cancel"
      cancel-variant="outline-primary"
      footer-class="justify-content-end flex-row-reverse"
      centered
    >
      <b-form-group
        label="Amount"
        label-for="loan-amount"
      >
        <b-input-group
          id="loan-amount"
          prepend="$"
          append=".00"
          class="input-group-merge"
        >
          <b-form-input placeholder="Amount" />
        </b-input-group>
      </b-form-group>
    </b-modal>

    <!--------------------- Transaction delete Modal --------------------->
    <b-modal
      id="transaction-delete-modal"
      :ok-title="$t('Yes, delete it!')"
      :cancel-title="$t('cancel')"
      footer-class="justify-content-center flex-row-reverse"
      body-class="text-center"
      cancel-variant="outline-danger"
      size="md"
      hide-header
      centered
      @ok="deleteTransaction"
    >
      <div class="d-flex justify-content-center py-1">
        <feather-icon
          icon="AlertCircleIcon"
          size="80"
          style="color: #FF9F43;"
        />
      </div>
      <h4 class="px-2 mb-2">
        {{ $t('Are you sure to delete this transaction?') }}
      </h4>
      <h6 class="text-muted">
        {{ $t('You wont be able to revert this!') }}
      </h6>
    </b-modal>

  </div>
</template>
<script>
import {
  BTable, BModal, VBModal, BDropdown, BDropdownItem, BFormGroup, BInputGroup, BFormInput,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import { mapGetters } from 'vuex'

export default {
  components: {
    BTable,
    BModal,
    BDropdown,
    BDropdownItem,
    BFormGroup,
    BInputGroup,
    BFormInput,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      sortBy: 'createdAt',
      isSortDirDesc: true,
      selectedData: null,
      fields: [
        {
          key: 'createdAt',
          label: 'Date',
          sortable: true,
          class: 'text-center',
        },
        {
          key: 'principal',
          label: 'Loan',
          sortable: true,
          class: 'text-center',
        },
        {
          key: 'interestRate',
          label: 'Interest rate',
          sortable: true,
          class: 'text-center',
        },
        {
          key: 'payable',
          label: 'Payable',
          sortable: true,
          class: 'text-center',
        },
        {
          key: 'paymentReceived',
          label: 'Received',
          sortable: true,
          class: 'text-center',
        },
        {
          key: 'penalty',
          label: 'Penalty',
          sortable: true,
          class: 'text-center',
        },
        {
          key: 'allowance',
          label: 'allowance',
          sortable: true,
          class: 'text-center',
        },
        {
          key: 'note',
          label: 'note',
        },
        {
          key: 'type',
          label: 'type',
          sortable: true,
          class: 'text-center',
        },
        {
          key: 'action',
          label: 'Action',
          class: 'text-center',
        },
      ],
    }
  },
  computed: {
    ...mapGetters(['getCurrentCustomer', 'getCustomerTransactions']),
  },
  methods: {
    deleteTransaction() {
      if (!this.selectedData.loanDateTime || this.selectedData.loanDateTime === '' || this.getCurrentCustomer.loan.loanDateTime === '' || this.getCurrentCustomer.loan.loanDateTime.isEqual(this.selectedData.loanDateTime)) {
        store.dispatch('deleteTransaction', { ...this.selectedData, customer: this.getCurrentCustomer })
      }
    },
  },
}
</script>
