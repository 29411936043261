<template>
  <div>
    <b-card-text class="mb-1">
      {{ $t('guarantor') }}
    </b-card-text>
    <b-row>
      <b-col cols="4">
        <b-form-group
          :label="$t('guarantor name')"
          label-for="guarantorName"
        >
          <b-form-input
            id="guarantorName"
            v-model="items.name"
            :placeholder="$t('guarantor name')"
            :disabled="editDisabled"
            :state="validateAction ? validations.find(d => d.key === 'name').validate : null"
            @change="e => changeValue('name', e)"
          />
        </b-form-group>
      </b-col>
      <b-col cols="4">
        <b-form-group
          :label="$t('contact number')"
          label-for="guarantorContactNumber"
        >
          <b-form-input
            id="guarantorContactNumber"
            v-model="items.phoneNumber"
            :placeholder="$t('contact number')"
            :disabled="editDisabled"
            :state="validateAction ? validations.find(d => d.key === 'phoneNumber').validate : null"
            @change="e => changeValue('phoneNumber', e)"
          />
        </b-form-group>
      </b-col>
      <b-col cols="4">
        <b-form-group
          :label="$t('address')"
          label-for="guarantorAddress"
        >
          <b-form-input
            id="guarantorAddress"
            v-model="items.address"
            :placeholder="$t('address')"
            :disabled="editDisabled"
            :state="validateAction ? validations.find(d => d.key === 'address').validate : null"
            @change="e => changeValue('address', e)"
          />
        </b-form-group>
      </b-col>
      <b-col cols="4">
        <b-form-group
          :label="$t('guaranteed amount')"
          label-for="guarantorAmount"
        >
          <b-input-group
            id="guarantorAmount"
            prepend="$"
            append=".00"
            class="input-group-merge"
            :state="validateAction ? validations.find(d => d.key === 'amount').validate : null"
          >
            <b-form-input
              v-model="items.amount"
              :disabled="editDisabled"
              placeholder="0"
              @change="e => changeValue('amount', e)"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="4">
        <b-form-group
          :label="$t('guaranteed days')"
          label-for="guarantorDays"
        >
          <b-input-group
            id="guarantorDays"
            :append="$t('days')"
            class="input-group-merge"
            :state="validateAction ? validations.find(d => d.key === 'days').validate : null"
          >
            <b-form-input
              v-model="items.days"
              :disabled="editDisabled"
              placeholder="0"
              @change="e => changeValue('days', e)"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-form-group
          :label="$t('guarantor content')"
          label-for="guarantorContent"
        >
          <b-form-textarea
            id="guarantorContent"
            v-model="items.note"
            :placeholder="$t('guarantor content')"
            :disabled="editDisabled"
            rows="5"
            @change="e => changeValue('note', e)"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BCardText, BRow, BCol, BFormGroup, BFormInput, BFormTextarea, BInputGroup,
} from 'bootstrap-vue'

export default {
  components: {
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BFormTextarea,
  },
  props: {
    items: {
      type: Object,
      default: null,
    },
    validations: {
      type: Array,
      default: null,
    },
    validateAction: Boolean,
    editDisabled: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
  },
  methods: {
    changeValue(key, value) {
      this.$emit('change', key, value)
    },
  },
}
</script>
