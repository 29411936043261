<template>
  <div>
    <b-card-text class="mb-1">
      {{ $t('basic info') }}
    </b-card-text>
    <b-media
      v-if="showAvatar"
      no-body
      class="mb-2"
    >
      <b-media-aside
        class="mr-1"
      >
        <label for="fileInput">
          <input
            id="fileInput"
            type="file"
            hidden
            accept="image/*"
            @change="fileChange"
          >
          <b-avatar
            :src="imgFile"
            size="6rem"
          />
        </label>
      </b-media-aside>
      <b-media-body class="my-auto font-small-3">
        <b-card-text class="mb-0">
          {{ $t('please upload your image') }}
        </b-card-text>
        <b-card-text class="mb-0">
          {{ $t('JPG, GIF or PNG are allowed. The maximum photo size is 800kB') }}
        </b-card-text>
      </b-media-body>
    </b-media>
    <b-row>
      <b-col sm="4">
        <b-form-group
          :label="$t('name')"
          label-for="fullName"
        >
          <b-form-input
            id="fullName"
            v-model="items.fullName"
            :placeholder="$t('name')"
            :disabled="editDisabled"
            :state="validateAction ? validations.find(d => d.key === 'fullName').validate : null"
            @change="e => changeValue('fullName', e)"
          />
        </b-form-group>
      </b-col>
      <b-col sm="4">
        <b-form-group
          :label="$t('group')"
          label-for="group"
        >
          <b-dropdown
            v-if="groups"
            id="group"
            :disabled="editDisabled || groups.length <= 0"
            :text="group"
            variant="outline-dark"
            class="w-100 mb-1 full-width-dropdown"
          >
            <b-dropdown-item
              v-for="item in groups"
              :key="item.groupName"
              @click="changeValue('group', item.groupName)"
            >
              {{ item.groupName }}
            </b-dropdown-item>
          </b-dropdown>
        </b-form-group>
      </b-col>
      <b-col sm="4">
        <b-form-group
          :label="$t('account id')"
          label-for="accountNumber"
        >
          <b-form-input
            id="accountNumber"
            :placeholder="$t('account id')"
            :disabled="editDisabled"
            :value="items.accountNumber"
            :state="validateAction ? validations.find(d => d.key === 'accountNumber').validate : null"
            @change="e => changeValue('accountNumber', e)"
          />
        </b-form-group>
      </b-col>
      <b-col sm="4">
        <b-form-group
          :label="$t('date of birth')"
          label-for="birthday"
        >
          <loan-date-picker
            id="birthday"
            :disabled="editDisabled"
            :value="items.birthday"
            :state="validateAction ? validations.find(d => d.key === 'birthday').validate : null"
            @change="e => changeValue('birthday', e)"
          />
        </b-form-group>
      </b-col>
      <b-col sm="4">
        <b-form-group
          :label="$t('id number')"
          label-for="idNumber"
        >
          <b-form-input
            id="idNumber"
            v-model="items.idNumber"
            :placeholder="$t('id number')"
            :disabled="editDisabled"
            :state="validateAction ? validations.find(d => d.key === 'idNumber').validate : null"
            @change="e => changeValue('idNumber', e)"
          />
        </b-form-group>
        <small class="text-danger">{{ errors.idNumber }}</small>
      </b-col>
      <b-col sm="4">
        <b-form-group
          :label="$t('bank account')"
          label-for="bankAccount"
        >
          <b-form-input
            id="bankAccount"
            v-model="items.bankInfo.bankAccount"
            :placeholder="$t('bank account')"
            :disabled="editDisabled"
            :state="validateAction ? validations.find(d => d.key === 'bankInfo').validate : null"
            @change="e => changeValue('bankInfo.bankAccount', e)"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-card-text class="mb-1 mt-1">
      {{ $t('contact') }}
    </b-card-text>
    <b-row>
      <b-col sm="4">
        <b-form-group
          :label="$t('cell phone')"
          label-for="cellPhoneNumber"
        >
          <b-form-input
            id="cellPhoneNumber"
            v-model="items.cellPhoneNumber"
            :placeholder="$t('phone number')"
            :disabled="editDisabled"
            :state="validateAction ? validations.find(d => d.key === 'cellPhoneNumber').validate : null"
            @change="e => changeValue('cellPhoneNumber', e)"
          />
        </b-form-group>
      </b-col>
      <b-col sm="4">
        <b-form-group
          :label="$t('home phone')"
          label-for="homePhoneNumber"
        >
          <b-form-input
            id="homePhoneNumber"
            v-model="items.homePhoneNumber"
            placeholder="Phone Number"
            :disabled="editDisabled"
            @change="e => changeValue('homePhoneNumber', e)"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-card-text class="mb-1 mt-1">
      {{ $t('address') }}
    </b-card-text>
    <b-row>
      <b-col sm="4">
        <b-form-group
          :label="$t('address')"
          label-for="address"
        >
          <b-form-input
            id="address"
            v-model="items.address"
            :placeholder="$t('address')"
            :disabled="editDisabled"
            :state="validateAction ? validations.find(d => d.key === 'address').validate : null"
            @change="e => changeValue('address', e)"
          />
        </b-form-group>
      </b-col>
      <b-col sm="4">
        <b-form-group
          :label="$t('house ownership')"
          label-for="houseOwnership"
        >
          <b-form-radio-group
            id="houseOwnership"
            v-model="items.houseOwnership"
            :disabled="editDisabled"
            @change="e => changeValue('houseOwnership', e)"
          >
            <div class="mt-1">
              <b-form-radio
                name="houseOwnership"
                value="own"
              >
                {{ $t('own') }}
              </b-form-radio>
              <b-form-radio
                name="houseOwnership"
                value="rent"
              >
                {{ $t('rent') }}
              </b-form-radio>
            </div>
          </b-form-radio-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-card-text class="mb-1 mt-1">
      {{ $t('company info') }}
    </b-card-text>
    <b-row>
      <b-col sm="4">
        <b-form-group
          :label="$t('job occupation')"
          label-for="jobOccupation"
        >
          <b-form-input
            id="jobOccupation"
            v-model="items.jobOccupation"
            :placeholder="$t('job occupation')"
            :disabled="editDisabled"
            :state="validateAction ? validations.find(d => d.key === 'jobOccupation').validate : null"
            @change="e => changeValue('jobOccupation', e)"
          />
        </b-form-group>
      </b-col>
      <b-col sm="4">
        <b-form-group
          :label="$t('company name')"
          label-for="companyName"
        >
          <b-form-input
            id="companyName"
            v-model="items.companyName"
            :placeholder="$t('company name')"
            :disabled="editDisabled"
            @change="e => changeValue('companyName', e)"
          />
        </b-form-group>
      </b-col>
      <b-col sm="4">
        <b-form-group
          :label="$t('company number')"
          label-for="companyPhoneNumber"
        >
          <b-form-input
            id="companyPhoneNumber"
            v-model="items.companyPhoneNumber"
            :placeholder="$t('company number')"
            :disabled="editDisabled"
            :state="validateAction ? validations.find(d => d.key === 'companyPhoneNumber').validate : null"
            @change="e => changeValue('companyPhoneNumber', e)"
          />
        </b-form-group>
      </b-col>
      <b-col sm="4">
        <b-form-group
          :label="$t('company address')"
          label-for="companyAddress"
        >
          <b-form-input
            id="companyAddress"
            v-model="items.companyAddress"
            :placeholder="$t('company address')"
            :disabled="editDisabled"
            :state="validateAction ? validations.find(d => d.key === 'companyAddress').validate : null"
            @change="e => changeValue('companyAddress', e)"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BCardText, BMedia, BMediaAside, BMediaBody, BAvatar, BFormGroup, BFormInput, BRow, BCol, BDropdown, BDropdownItem, BFormRadio, BFormRadioGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import LoanDatePicker from '@/views/components/elements/LoanDatePicker.vue'
import store from '@/store'
import { mapGetters } from 'vuex'
import svgToMiniDataURI from 'mini-svg-data-uri'
import {
  isNationalIdentificationNumberValid,
  isResidentCertificateNumberValid,
} from 'taiwan-id-validator'

export default {
  components: {
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BAvatar,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    LoanDatePicker,
    BFormRadio,
    BFormRadioGroup,
  },
  directives: {
    Ripple,
  },
  props: {
    items: {
      type: Object,
      default: null,
    },
    validations: {
      type: Array,
      default: null,
    },
    validateAction: Boolean,
    showAvatar: {
      type: Boolean,
      default: false,
    },
    editDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      group: '',
      imgFile: null,
      errors: {},
    }
  },
  computed: {
    ...mapGetters({
      user: 'getUser',
      store: 'getCurrentStore',
      groups: 'getGroups',
    }),
  },
  watch: {
    group(newVal) {
      this.$emit('change', 'group', newVal)
    },
  },
  async created() {
    if (this.items.group) {
      const checkIndex = this.groups.map(item => item.groupName).indexOf(this.items.group)
      if (checkIndex >= 0) {
        this.group = this.items.group
      }
    } else if (this.groups[0]) this.group = this.groups[0].groupName

    this.group ||= ''

    await fetch(`https://api.dicebear.com/7.x/micah/svg?seed=${
      JSON.stringify(Math.floor(Math.random() * 1000000000))}`, { mode: 'cors' })
      .then(res => res.text())
      .then(svg => {
        this.imgFile = svgToMiniDataURI(svg)
        store.dispatch('app/setAvatarFile', { file: this.imgFile, save: false })
      })
  },
  methods: {
    changeValue(key, value) {
      if (key === 'group') {
        this.$set(this, 'group', value)
        this.$emit('change', key, value)
      } else if (key === 'idNumber') {
        const upValue = value.toUpperCase()
        this.items.idNumber = upValue
        if (isNationalIdentificationNumberValid(upValue) || isResidentCertificateNumberValid(upValue)) {
          delete this.errors[key]
          this.$emit('change', key, upValue)
        } else {
          this.errors.idNumber = this.$t('wrong format')
        }
      } else {
        this.$emit('change', key, value)
      }
    },
    fileChange(event) {
      const file = event.target.files[0]
      if (file) {
        store.dispatch('app/setAvatarFile', { file, save: false })
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
          this.$set(this, 'imgFile', reader.result)
        }
      }
    },
  },
}
</script>
