// 按期付息到期還本法 Balloon Mortgage
// 本金平均攤還法 Constant Amortization Mortgage loan，CAM
// 本利均等攤還法 Constant Payment Mortgage loan, CPM
// 還本寬限期法 漸進加付法Gradual Payment Mortgage，GPM

export const LoanTypes = [
  {
    key: 'balloon',
  },
  {
    key: 'CAM',
  },
  {
    key: 'CPM',
  },
  {
    key: 'GPM',
  },
]

export const StoreTypes = [
  {
    key: 'registered_company',
  },
  {
    key: 'private',
  },
  {
    key: 'pawnshop',
  },
  {
    key: 'other',
  },
]

export const ExpenseTypes = ['expense', 'created', 'refinance', 'lost', 'transaction_fee']
export const TransactionTypes = ['interest', 'penalty', 'closed', 'expense', 'fee', 'extend_date', 'created', 'refinance']
